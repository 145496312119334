export default defineNuxtRouteMiddleware(() => {
  const authStore = useAuthStore()
  const roles = authStore.roles

  const allowedRoles = new Set(['tour-admin'])

  if (roles.length === 0) {
    return navigateTo('/auth/login')
  }

  const hasRole = roles.some((role: any) => allowedRoles.has(role))
  if (!hasRole) {
    return navigateTo('/auth/login')
  }
})
